<template>
  <RouterView />
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  async mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.setPromptPwa(e);
    });
  },
  methods: {
    ...mapActions("interface", ["setPromptPwa"]),
  },
};
</script>
