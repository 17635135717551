import { buildRelationRequest } from "@/store/modules/relations";

export const useDocumentRelationBuilder = () => {
  const buildDocumentRelationsRequest = ({
    documentId,
    aosItems,
    typologyTypes,
    objectTypes,
    imageTypeId,
  }) => {
    const aosRelations = Object.keys(aosItems)
      .map((struct) =>
        aosItems[struct].map((aosId) => {
          return buildRelationRequest({
            appServiceId: "document",
            appEntityName: "document",
            appEntityId: documentId,
            targetService: "aos",
            targetEntityName: struct,
            targetEntityId: aosId,
            linkType: "DEFAULT",
          });
        })
      )
      .flat();
    const typologyRelations = typologyTypes.map((uuid) =>
      buildRelationRequest({
        appServiceId: "document",
        appEntityName: "document",
        appEntityId: documentId,
        targetService: "amos",
        targetEntityName: "typologytype",
        targetEntityId: uuid,
        linkType: "DEFAULT",
      })
    );
    const objectTypeRelations = objectTypes.map((uuid) =>
      buildRelationRequest({
        appServiceId: "document",
        appEntityName: "document",
        appEntityId: documentId,
        targetService: "amos",
        targetEntityName: "aosobjecttype",
        targetEntityId: uuid,
        linkType: "DEFAULT",
      })
    );

    const imageTypeRelations = [];
    if (imageTypeId) {
      imageTypeRelations.push(
        buildRelationRequest({
          appServiceId: "document",
          appEntityName: "document",
          appEntityId: documentId,
          targetService: "aos",
          targetEntityName: "documenttype",
          targetEntityId: imageTypeId,
          linkType: "DEFAULT",
        })
      );
    }

    return [
      ...aosRelations,
      ...typologyRelations,
      ...objectTypeRelations,
      ...imageTypeRelations,
    ];
  };

  return {
    buildDocumentRelationsRequest,
  };
};
