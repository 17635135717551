import { ref } from "vue";
import { isEqual } from "lodash";

export const observtionEditableFields = [
  ["ctComment", (a, b) => a === b],
  ["refCode", (a, b) => a === b],
  ["isValidated", (a, b) => a === b],
  ["riskAssessment", (a, b) => Boolean(a) === Boolean(b)],
  ["aosObservablesList", (a, b) => isEqual(a, b)],
  ["functionalRequirementsList", (a, b) => isEqual(a, b)],
  ["amosDocumentsList", (a, b) => isEqual(a, b)],
  ["typologyTypesList", (a, b) => isEqual(a, b)],
  ["dispositionTypesList", (a, b) => isEqual(a, b)],
  ["metadata", (a, b) => isEqual(a, b)],
];

export const useObservationDashboardValidation = () => {
  const validForm = ref(false);

  const statementsContainChanges = ({ incoming, initial }) => {
    return JSON.stringify(incoming) !== JSON.stringify(initial);
  };

  const actionsContainChanges = ({ incoming, initial }) => {
    return JSON.stringify(incoming) !== JSON.stringify(initial);
  };

  const validateStatements = ({ incomingStatements, currentUser }) => {
    // Picasso CT must be able to save an observation without make any changes
    if (currentUser.isCT()) return true;
    return !!incomingStatements.length;
  };

  const isRiskValid = ({ projectConfig, observation }) => {
    if (!projectConfig.hasRisk) return true;
    return !!observation.riskAssessment;
  };

  const isRefCodeValid = ({ isPicassoVisit, observation }) => {
    if (!isPicassoVisit) return true;
    return !!observation?.refCode;
  };

  const validateActions = ({
    incomingActions,
    initialActions,
    observation,
    projectConfig,
  }) => {
    if (!observation) return false;
    if (!projectConfig.hasActions) return true;

    // Contrary to statements, it is possible to save an observation without any actions
    if (!initialActions.length || !incomingActions.length) return true;
  };

  const observationContainsChanges = ({ incoming, initial }) => {
    if (incoming && !initial) return true;
    // If the observation is not new, we need to check if the observation has been modified ==> at least one field has been changed

    return observtionEditableFields.some(([field, comparatorFn]) => {
      const hasDiff = !comparatorFn(incoming[field], initial[field]);
      return hasDiff;
    });
  };

  const validateObservation = ({
    observation,
    isPicassoVisit,
    projectConfig,
  }) => {
    const refCodeValid = isRefCodeValid({ isPicassoVisit, observation });
    const riskValid = isRiskValid({ projectConfig, observation });
    return refCodeValid && riskValid;
  };

  const validateObservationForm = ({
    projectConfig,
    observation,
    initialObservation,
    isPicassoVisit,
    initialStatements,
    incomingStatements,
    incomingActions,
    initialActions,
    currentUser,
  }) => {
    if (!observation) return false;
    const isObservationValid = validateObservation({
      observation,
      isPicassoVisit,
      projectConfig,
    });
    const isObservationChanged = observationContainsChanges({
      incoming: observation,
      initial: initialObservation,
    });

    const areStatementsChanged = statementsContainChanges({
      incoming: incomingStatements,
      initial: initialStatements,
    });
    const areStatementsValid = validateStatements({
      incomingStatements,
      currentUser,
    });

    const areActionsChanged = actionsContainChanges({
      incoming: incomingActions,
      initial: initialActions,
    });
    const areActionsValid = validateActions({
      incomingActions,
      initialActions,
      observation,
      projectConfig,
    });

    const baseValidation =
      isObservationValid && areStatementsValid && areActionsValid;

    const formContainsChanges =
      isObservationChanged || areStatementsChanged || areActionsChanged;

    validForm.value = baseValidation && formContainsChanges;
  };

  return {
    validateObservationForm,
    validForm,
  };
};
