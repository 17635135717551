const stonlyIdentifyWidget = (email, name) => {
  let script = document.createElement("script");
  script.text = `StonlyWidget('identify', '${email}', {
                                  'nom-utilisateur': '${name}',
                                  'email': '${email}',
                                  });`;
  document.getElementsByTagName("body")[0].appendChild(script);
};

export { stonlyIdentifyWidget };
